.Autocomplete {
  margin: 0;
  background: white;
  padding: 1em 0.5em 0.5em 0.25em;
  border: 1px solid gray;

  &__dropdownSection {
    padding-bottom: 0.5em;
  }

  &__option {
    cursor: pointer;
    padding-left: 0.5em;

    &--focused {
      background: aliceblue;
    }

    &:hover {
      background: lightgreen;
    }
  }
}